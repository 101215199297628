import Vue from 'vue';

import {KEYS_LOCALSTORAGE} from '@/constants';
import CountryFlag from 'vue-country-flag';

Vue.component('country-flag', CountryFlag);

Vue.component('country-flag', CountryFlag);

export default Vue.extend({
  name: 'LanguageManager',

  data: () => ({
    langs: ['en', 'es', 'nl'],
  }),

  methods: {
    setCurrentLanguage(lang) {
      localStorage.setItem(KEYS_LOCALSTORAGE.LANGUAGE, lang);
      this.$i18n.locale = lang;
    },
  },
});
